// @ts-expect-error Interframe is not yet migrated
import Interframe from 'interframe';
// @ts-expect-error Interframe is not yet migrated
import { MessageTypes as InterframeMessageTypes } from 'interframe/Constants';
import { Flow } from 'signup-constants/Flow';
import { Environment } from 'signup-ui-lego-core/constants/Environment';
import { Language } from 'signup-ui-lego-core/constants/Language';
import { SignupDatapoint } from 'signup-ui-lego-core/constants/SignupDataPoint';
import { ACKNOWLEDGE_MESSAGE_TYPE, COOKIE_CONSENT_MESSAGE_TYPE, HANDSHAKE_MESSAGE_TYPE, SIGNUP_DATA_MESSAGE_TYPE, SIGNUP_REDIRECT_MESSAGE_TYPE, SIGNUP_STARTED_MESSAGE_TYPE } from './constants/MessageTypes';
import { SignupEmbedderEnvironment } from './SignupEmbedderEnvironment';
import { SignupEmbedderOptions } from './SignupEmbedderOptions';
import { SignupPostMessageListeners } from './SignupPostMessageListeners';
import { addQueryParam } from './utils/addQueryParam';
import { installPopupBlockedRedirectTimer } from './utils/installPopupBlockedRedirectTimer';
import { getWindowOptions } from './utils/getWindowOptions';
import { uuid } from './utils/uuid';
export class SignupEmbedder {
  constructor() {
    this.options = new SignupEmbedderOptions();
    this.flow = Flow.Crm;
    this.prefillableSignupDatapoints = new Set([SignupDatapoint.CompanyDomain, SignupDatapoint.CompanyName, SignupDatapoint.Email, SignupDatapoint.FirstName, SignupDatapoint.LastName]);
    this.query = new Map();
    this.environment = Environment.prod;
    this.signupEnvironment = new SignupEmbedderEnvironment(this);
    this.signupData = {};
    this.postMessageListeners = new SignupPostMessageListeners();
    this.handshaked = false;
    this.handleInterframeMessage = message => {
      const {
        type
      } = message;
      if (type) {
        this.postMessageListeners.getListeners(type).forEach(({
          listener
        }) => {
          listener.apply(this, [message]);
        });
      }
    };
  }
  /**
   * Holds an instance of Interframe to enable communication
   * from the page embedding Signup with the Signup instance.
   *
   * @type {Interframe}
   * @memberof SignupEmbedder
   */
  /**
   * Holds an instance of SignupEmbedderOptions containing all
   * options to be included on the embedded signup url.
   *
   * @type {SignupEmbedderOptions}
   * @memberof SignupEmbedder
   */
  /**
   * The targeted signup flow to be used.
   *
   * @type {Flow}
   * @memberof SignupEmbedder
   */
  /**
   * Define the signup datapoints that can be prefilled via the setSignupData method.
   *
   * @type {Set<SignupDatapoint>}
   * @memberof SignupEmbedder
   */
  /**
   * Holds the map of query parameters to be added.
   *
   * @type {Map<string, string>}
   * @memberof SignupEmbedder
   */
  /**
   * Holds the target signup environment. Defaults to "production".
   *
   * @type {Environment}
   * @memberof SignupEmbedder
   */
  /**
   * Holds an instance of SignupEmbedderEnvironment containing
   * details about the signup environment being used.
   *
   * @private
   * @type {SignupEmbedderEnvironment}
   * @memberof SignupEmbedder
   */
  /**
   * Holds an instance of SignupPostMessageListeners.
   *
   * @private
   * @type {SignupPostMessageListeners}
   * @memberof SignupEmbedder
   */
  /**
   * Holds the flag indicating that this embedder instance had a
   * success handshake with the signup instance.
   *
   * @type {boolean}
   * @memberof SignupEmbedder
   */
  /**
   * Used to determine whether the signup is embedded or not.
   *
   * @static
   * @returns True if the signup instance is embedded. False otherwise.
   * @memberof SignupEmbedder
   */
  static isEmbedded() {
    return window.top !== window.self;
  }

  /**
   * Used to determine whether the signup is in a popup or not.
   *
   * @static
   * @returns True if the signup instance is in a popup. False otherwise.
   * @memberof SignupEmbedder
   */
  static isPopup() {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    return Boolean(searchParams.get('isSignupPopup'));
  }

  /**
   * Used to determine whether the signup is in a popup or embedded.
   *
   * @static
   * @returns {Boolean} True if the signup instance is in a popup. False otherwise.
   * @memberof SignupEmbedder
   */
  static isActive() {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const hasSignupEmbedderIsActiveOptionParameter = Boolean(searchParams.get('_seia'));
    return this.isPopup() || this.isEmbedded() || hasSignupEmbedderIsActiveOptionParameter;
  }

  /**
   * Sets a callback to be triggered when a signup sends
   * a SIGNUP_REDIRECT post message.
   *
   * @param {string} type
   * @param {Function} callback
   * @returns {SignupEmbedder}
   * @memberof SignupEmbedder
   */
  addPostMessageListener(type, callback) {
    this.postMessageListeners.push(type, callback);
    return this;
  }

  /**
   * Sets the options.
   *
   * @param {SignupEmbedderOptions} options
   * @returns {SignupEmbedder}
   * @memberof SignupEmbedder
   */
  setOptions(options) {
    if (!(options instanceof SignupEmbedderOptions)) {
      throw new Error(`The options arguments needs to be an instance of SignupEmbedderOptions`);
    }
    this.options = options;
    return this;
  }

  /**
   * Sets the target flow.
   *
   * @param {Flow} flow
   * @returns {SignupEmbedder}
   * @memberof SignupEmbedder
   */
  setFlow(flow) {
    if (!Object.values(Flow).includes(flow)) {
      throw new Error(`The flow ${flow} is invalid. Valid options are: ${Object.values(Flow).join(', ')}`);
    }
    this.flow = flow;
    return this;
  }

  /**
   * Sets the language.
   *
   * @param {(keyof typeof Language | Language)} language
   * @returns {SignupEmbedder}
   * @memberof SignupEmbedder
   */
  setLanguage(language) {
    if (!Object.values(Language).includes(language)) {
      throw new Error(`The language ${language} is invalid. Valid options are: ${Object.values(Language).join(', ')}`);
    }
    const lang = typeof language !== 'string' ? Language[language] : language;
    this.addQueryParam('lang', lang);
    return this;
  }

  /**
   * Sets the environment.
   *
   * @param {(keyof typeof Environment | Environment)} environment
   * @returns {SignupEmbedder}
   * @memberof SignupEmbedder
   */
  setEnvironment(environment) {
    if (!Object.values(Environment).includes(environment)) {
      throw new Error(`The environment ${environment} is invalid. Valid options are: ${Object.values(Environment).join(', ')}`);
    }
    this.environment = typeof environment === 'string' ? Environment[environment] : environment;
    return this;
  }

  /**
   * Set a query string object or entry to be added.
   *
   * @param {string|object} queryName The query string key or a key-value object.
   * @param {string?} queryValue The query string value. Not required if key-value setter is used.
   * @returns {SignupEmbedder}
   */
  addQueryParam(queryName, queryValue) {
    if (typeof queryName === 'object' && queryValue === undefined) {
      const obj = queryName;
      Object.entries(obj).forEach(([key, value]) => {
        addQueryParam(key, value, this.query);
      });
    } else if (queryName && typeof queryName === 'string' && queryValue && typeof queryValue === 'string') {
      addQueryParam(queryName, queryValue, this.query);
    }
    return this;
  }

  /**
   * Sets the signup data.
   *
   * @param {string|object} propName
   * @param {string?} propValue
   * @returns {SignupEmbedder}
   */
  setSignupData(propName, propValue) {
    if (typeof propName === 'object' && propValue === undefined) {
      const obj = propName;
      Object.entries(obj).forEach(([key, value]) => {
        const datapoint = key;
        if (datapoint && this.prefillableSignupDatapoints.has(datapoint) && value !== undefined && value !== null) {
          this.signupData = Object.assign({}, this.signupData, {
            [key]: value
          });
        }
      });
    } else if (propName && typeof propName === 'string' && propValue && typeof propValue === 'string') {
      if (this.prefillableSignupDatapoints.has(propName)) {
        this.signupData = Object.assign({}, this.signupData, {
          [propName]: propValue
        });
      }
    }
    return this;
  }

  /**
   * Sets the element we should attach a new instance of the SignupEmbedded.
   *
   * @param {(HTMLElement | string)} element
   * @returns {SignupEmbedder}
   * @memberof SignupEmbedder
   */
  attachTo(element) {
    if (!element) {
      throw new Error(`You must provide a valid HTML element to attach an instance of signup. The value received was ${element}.`);
    }
    let attachableElement;
    if (!(element instanceof HTMLElement) && typeof element === 'string') {
      const queryElement = document.querySelector(element);
      if (!queryElement) {
        throw new Error(`You must provide a valid HTML element to attach an instance of signup. The value received was ${element}.`);
      }
      attachableElement = queryElement;
    } else {
      attachableElement = element;
    }
    const iframe = this.buildIframe();
    this.beforeAttach();
    this.prepareElement(attachableElement).appendChild(iframe);
    if (iframe.contentWindow) {
      this.setupInterframe(iframe.contentWindow);
    }
    return this;
  }

  /**
   * Opens a new popup with an instance of the SignupEmbedder.
   *
   * @param {boolean} [goToLogin=false]
   * @param {string} [windowOptions=getWindowOptions()]
   * @returns {(Window | null)}
   * @memberof SignupEmbedder
   */
  asPopup(goToLogin = false, windowOptions = getWindowOptions()) {
    const url = goToLogin ? this.signupEnvironment.getLoginUrl(true) : this.signupEnvironment.getSignupUrl(true);
    const popup = window.open(url, 'Get started with HubSpot', windowOptions);
    try {
      if (!popup || popup.closed || typeof popup.closed === 'undefined') {
        throw new Error(`Popup failed to open: ${new URL(url).pathname}`);
      } else {
        popup.focus();
      }
    } catch (e) {
      throw new Error(`Popup failed to open: ${new URL(url).pathname}`);
    }
    this.beforeAttach();
    if (popup) {
      this.setupInterframe(popup);
      const embedderOptionsToKeepForPopupBlocked = [SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_ID, SignupEmbedderOptions.SIGNUP_OAUTH_REDIRECT, SignupEmbedderOptions.SIGNUP_OAUTH_SCOPES, SignupEmbedderOptions.SIGNUP_OAUTH_CLIENT_STATE, SignupEmbedderOptions.SIGNUP_UUID_OVERRIDE, SignupEmbedderOptions.SIGNUP_DEVIDE_ID_OVERRIDE_OPTION, SignupEmbedderOptions.SIGNUP_POPUP_BLOCKED];
      const signupRedirectUrl = goToLogin ? this.signupEnvironment.getLoginUrl(true, true, embedderOptionsToKeepForPopupBlocked) : this.signupEnvironment.getSignupUrl(true, true, embedderOptionsToKeepForPopupBlocked);
      const timeout = installPopupBlockedRedirectTimer({
        signupRedirectUrl,
        popup,
        postMessageListeners: this.postMessageListeners
      });
      window.onbeforeunload = () => {
        clearTimeout(timeout);
      };
    }
    return popup;
  }

  /**
   * Similar to method above, but it takes an object as prop
   */
  openAsPopup({
    goToLogin = false,
    windowOptions = getWindowOptions()
  }) {
    return this.asPopup(goToLogin, windowOptions);
  }

  /**
   * Returns an object with the data needed to open a new popup with the signup url
   * ONLY MEANT OT BE USED FOR TESTING
   *
   * @param {boolean} [goToLogin=false]
   * @returns {EmbeddedPopupConfiguration}
   * @memberof SignupEmbedder
   */
  getSignupPopupConfiguration(goToLogin = false) {
    return {
      url: goToLogin ? this.signupEnvironment.getLoginUrl() : this.signupEnvironment.getSignupUrl(),
      windowOptions: getWindowOptions(),
      title: 'Get started with HubSpot'
    };
  }
  getEnvironment() {
    return this.environment;
  }
  getOptions() {
    return this.options;
  }
  getFlow() {
    return this.flow;
  }
  getQuery() {
    return this.query;
  }
  getInterframe() {
    return this.interframe;
  }
  getPostMessageListeners() {
    return this.postMessageListeners;
  }

  /**
   * Setup Interframe.
   *
   * @param {HTMLIFrameElement} iframe
   * @memberof SignupEmbedder
   */
  setupInterframe(windowInstance) {
    const referrer = this.signupEnvironment.getSignupDomain();
    this.interframe = new Interframe(referrer, windowInstance, true);
    setTimeout(() => {
      this.interframe.subscribe(this.handleInterframeMessage);
    });
  }

  /**
   * Adds some default post message listener in case
   * none are passed from within the SignupEmbedder construction.
   *
   * @memberof SignupEmbedder
   */
  setupDefaultPostMessageHandlers() {
    if (!this.postMessageListeners.hasListeners(SIGNUP_REDIRECT_MESSAGE_TYPE)) {
      this.addPostMessageListener(SIGNUP_REDIRECT_MESSAGE_TYPE, this.handleSignupRedirectMessage);
    }
    this.addPostMessageListener(SIGNUP_STARTED_MESSAGE_TYPE, this.handleAppStartedMessage);
  }

  /**
   * Tasks to be executed before attaching this.
   *
   * @memberof SignupEmbedder
   */
  beforeAttach() {
    this.setupDefaultPostMessageHandlers();
  }

  /**
   * Posts a message informing signup about the cookie consent.
   * This method will only post the message if the option hideCookieBanner is true
   * on the options set for this instance.
   *
   * @param {{ allowed: Boolean }} consent
   * @memberof SignupEmbedder
   */
  postCookieConsentMessage(consent, deviceId = uuid()) {
    this.interframe.postMessage({
      type: COOKIE_CONSENT_MESSAGE_TYPE,
      consent,
      deviceId
    }, InterframeMessageTypes.JSON);
  }

  /**
   * Handles all interframe message and delegate it to the specific handler.
   *
   * @param {{ type: string }} message
   * @memberof SignupEmbedder
   */

  /**
   * Handles the application started method.
   *
   * @memberof SignupEmbedder
   */
  handleAppStartedMessage() {
    this.interframe.postMessage({
      type: HANDSHAKE_MESSAGE_TYPE
    }, InterframeMessageTypes.JSON).then(response => {
      this.handshaked = response.type === ACKNOWLEDGE_MESSAGE_TYPE;
    }).catch(error => console.log(`SignupEmbedder Error: ${error}`));
    if (Object.keys(this.signupData).length > 0) {
      this.interframe.postMessage({
        type: SIGNUP_DATA_MESSAGE_TYPE,
        data: this.signupData
      }, InterframeMessageTypes.JSON);
    }
  }

  /**
   * Handles the SIGNUP_REDIRECT post message.
   *
   * @protected
   * @param {SignupRedirectMessage} message
   * @memberof SignupEmbedder
   */
  handleSignupRedirectMessage(message) {
    const {
      url
    } = message;
    if (url) {
      window.location.replace(url);
    }
  }

  /**
   * Prepares a wrapper element to create a responsive wrapper around the iframe.
   *
   * @param {HTMLElement} element
   * @returns {HTMLElement} The wrapper element.
   */
  prepareElement(element) {
    const wrapper = document.createElement('div');
    wrapper.classList.add('signup-embedded__wrapper');
    wrapper.style.position = 'relative';
    wrapper.style.width = '100%';
    wrapper.style.height = '100%';
    wrapper.style.minHeight = '800px';
    element.appendChild(wrapper);
    return wrapper;
  }

  /**
   * Builds a new iframe element.
   *
   * @returns {HTMLIFrameElement} The iframe element.
   */
  buildIframe() {
    const iframe = document.createElement('iframe');
    iframe.classList.add('signup-embedded__iframe');
    iframe.id = 'signup-embedded';
    iframe.title = this.options.title;
    iframe.style.position = 'absolute';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';
    iframe.referrerPolicy = 'origin';
    iframe.scrolling = 'yes';
    iframe.allow = 'keyboard-map *';
    iframe.src = this.signupEnvironment.getSignupUrl();
    console.log(iframe.src);
    return iframe;
  }
}