'use es6';

const StyleSheet = {
  /**
   * Get a <style> tag with the CSS for the app
   *
   * We do this to minimize the number of requests that need to be made to create an alarm. Loading via SASS would
   * necessitate a seperate request.
   *
   * Styles are taken from the UiFireAlarm component
   *
   * @see https://tools.hubteam.com/ui-library/show/UIComponents/alert/UIFireAlarm
   * @return DomNode
   */
  getCss: () => {
    const styles = `
      .fire-alarm_alarms {
        box-sizing: border-box;
        color: rgb(255, 255, 255);
        font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
        font-weight: 500;
        font-size: 14px ;
        line-height: 24px;
      }

      .fire-alarm_alarms.fire-alarm_no-nav-margin-bottom {
        margin-bottom: 0;
        margin-top: 0;
      }

      .fire-alarm_alarms .fire-alarm_alarm {
        box-sizing: border-box;
        min-height: 60px;
        padding: 14px 20px;
        position: relative;
      }

      .fire-alarm_critsit {
        background-color: rgb(242, 84, 91);
        background-image: repeating-linear-gradient(135deg, rgb(240, 75, 81), rgb(240, 75, 81) 24px, rgb(242, 84, 91) 24px, rgb(242, 84, 91) 48px);
        border: 1px solid rgb(248, 169, 173);
      }

      .fire-alarm_outage{
        background-color: #fdf3e1;
        border: 1px solid #f5c26b;
      }

      .fire-alarm_maintenance,
      .fire-alarm_debug {
        background-color: #f2fafb;
        border: 1px solid #7fd1de;
      }

      .fire-alarm_alarms .fire-alarm_alarm-inner {
        align-items: baseline;
        display: flex;
        flex: 1;
      }

      .fire-alarm_alarms h2 {
        color: rgb(255, 255, 255);
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin: 0 20px 6px 0;
        text-shadow: transparent 0px 0px 1px;
      }

      .fire-alarm_alarms .fire-alarm_alarm-body {
        line-height: 1.4;
        padding-right: 24px;
      }

      .fire-alarm_alarms .fire-alarm_alarm-body p {
        font-size: 14px;
        line-height: 19.6px;
        margin: 0;
      }

      .fire-alarm_outage h2,
      .fire-alarm_maintenance h2,
      .fire-alarm_outage .fire-alarm_alarm-body p,
      .fire-alarm_maintenance .fire-alarm_alarm-body p {
        color: rgb(66, 91, 118);
      }

      .fire-alarm_alarms .fire-alarm_alarm-body a,
      .fire-alarm_alarms .fire-alarm_alarm-status-page-link {
        color: rgb(255, 255, 255);
        font-weight: 600;
        text-decoration: underline;
        text-shadow: transparent 0px 0px 1px;
        transition-delay: 0s;
        transition-duration: 0.15s;
        transition-property: all;
        transition-timing-function: ease-out;
      }

      .fire-alarm_outage .fire-alarm_alarm-body a,
      .fire-alarm_maintenance .fire-alarm_alarm-body a,
      .fire-alarm_outage .fire-alarm_alarm-status-page-link,
      .fire-alarm_maintenance .fire-alarm_alarm-status-page-link {
        color: rgb(0, 164, 189);
        font-weight: 500;
        text-decoration: none;
      }

      .fire-alarm_outage .fire-alarm_alarm-body a:hover,
      .fire-alarm_maintenance .fire-alarm_alarm-body a:hover,
      .fire-alarm_outage .fire-alarm_alarm-status-page-link:hover,
      .fire-alarm_maintenance .fire-alarm_alarm-status-page-link:hover {
        text-decoration: underline;
      }

      .fire-alarm_alarms .fire-alarm_alarm-close-button {
        background: scroll border-box transparent none 0% 0% repeat;
        border: 0px none;
        box-sizing: border-box;
        color: rgb(255, 255, 255);
        cursor: pointer;
        display: block;
        font-kerning: auto;
        height: 16px;
        line-height: 24px;
        margin: 0;
        outline-offset: 0px;
        overflow: visible;
        padding: 0px;
        position: absolute;
        right: 20px;
        text-align: start;
        text-transform: none;
        top: 20px;
        transition-delay: 0s;
        transition-duration: 0.15s;
        transition-property: all;
        transition-timing-function: ease-out;
        width: 16px;
      }

      .fire-alarm_outage .fire-alarm_alarm-close-button {
        color: rgb(245, 194, 107);
      }

      .fire-alarm_maintenance .fire-alarm_alarm-close-button {
        color: #7c98b6;
      }

      .fire-alarm_alarms .fire-alarm_alarm-close-button:after {
        display: block;
        background: rgba(255,255,255,0);
        border-radius: 100%;
        padding: 1.25rem;
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateZ(0) translate(-50%, -50%);
        transition: all 150ms ease-out;
      }

      .fire-alarm_alarms .fire-alarm_alarm-close-button:hover:after {
        background: rgba(255,255,255,0.1);
      }

      .fire-alarm_alarms .fire-alarm_alarm-close-button path {
        fill: currentColor;
        stroke: currentColor;
        stroke-width: 2;
      }

      .fire-alarm_alarms .fire-alarm_alarm-button-text {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        margin: -1px !important;
        padding: 0 !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        border: 0 !important;
      }
    `;
    const styleEl = document.createElement('style');
    styleEl.innerHTML = styles;
    return styleEl;
  }
};
export default StyleSheet;