/**
 * Generates color tokens for the external link icon
 * based on the given link use.
 * @param use - The link use value.
 * @returns The color tokens for the given use.
 */
export const getExternalIconColorTokensForUse = use => {
  switch (use) {
    case 'blue':
    case 'dark':
    case 'danger':
    case 'mention':
    case 'unstyled':
      return 'icon-core-default';
    case 'on-bright':
      return 'text-core-default';
    case 'on-dark':
      return 'text-interactive-on-fill-default';
    case 'disabled':
      return 'text-interactive-default-disabled';
    default:
      {
        const __exhaustiveCheck = use;
        throw new Error(`Unhandled \`use\` argument: ${use}`);
      }
  }
};

/**
 * Generates the color tokens for the given link use.
 * The tokens are provided for each interactive state.
 * @param variant - The link use value.
 * @returns The color tokens for the given use.
 */
export const getColorTokensForUse = use => {
  switch (use) {
    case 'blue':
    case 'dark':
      return {
        idle: 'text-interactive-default',
        hover: 'text-interactive-default-hover',
        active: 'text-interactive-default-pressed',
        disabled: 'text-interactive-default-disabled'
      };
    case 'mention':
    case 'unstyled':
    case 'on-bright':
      return {
        idle: 'text-core-default',
        hover: 'text-core-default',
        active: 'text-core-default',
        disabled: 'text-interactive-default-disabled'
      };
    case 'danger':
      return {
        idle: 'text-alert-default',
        hover: 'text-alert-default',
        active: 'text-alert-pressed',
        disabled: 'text-interactive-default-disabled'
      };
    case 'on-dark':
      return {
        idle: 'text-interactive-on-fill-default',
        hover: 'text-interactive-default-pressed',
        active: 'fill-tertiary-default',
        disabled: 'text-interactive-default-disabled'
      };
    default:
      {
        const __exhaustiveCheck = use;
        throw new Error(`Unhandled \`use\` argument: ${use}`);
      }
  }
};