import { useState, useCallback, useMemo } from 'react';
// @ts-expect-error no types from package
import HubSpotOnlyAppCookieLogin from 'Nabisco/app/HubSpotOnlyAppCookieLogin';
import { useRedirect } from '../../hooks/useRedirect';
import ConfirmToLoginAuthStatuses from '../../constants/ConfirmToLoginAuthStatuses';
import LoginDao from '../../data/LoginDao';
const loginDao = new LoginDao();
const appCookieLogin = new HubSpotOnlyAppCookieLogin({
  loginRequest: loginDao.otpLogin.bind(loginDao),
  passThroughFallback: true
});
const EXPIRED_LOGIN_LINK = 'EXPIRED_LOGIN_LINK';
const USED = 'USED_LOGIN_LINK';
export const TEMPLATE_MAP = {
  DEFAULT_LOGIN: 'DEFAULT_LOGIN',
  NO_AUTH_CHECKOUT: 'NO_AUTH_CHECKOUT'
};
export const useOneTimePassword = ({
  otp,
  otpId,
  next,
  template,
  userId
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showActionButton, setShowActionButton] = useState(false);
  const [isError, setIsError] = useState(false);
  const [responseLanguageKey, setResponseLanguageKey] = useState(undefined);
  const returnTemplateLanguageKey = useCallback(() => {
    switch (template) {
      case TEMPLATE_MAP.NO_AUTH_CHECKOUT:
        return 'purchase';
      case TEMPLATE_MAP.DEFAULT_LOGIN:
      default:
        return 'generic';
    }
  }, [template]);
  const {
    redirectToSuspiciousUser,
    redirectByStatus
  } = useRedirect();
  const handleError = useCallback(err => {
    if (err.responseJSON && err.responseJSON.status === ConfirmToLoginAuthStatuses.SUSPICIOUS_USER_MUST_CONFIRM) {
      redirectToSuspiciousUser(err);
    } else if (err.responseJSON && err.responseJSON.status === EXPIRED_LOGIN_LINK) {
      setIsLoading(false);
      setShowActionButton(true);
      setResponseLanguageKey('expired');
    } else if (err.responseJSON && err.responseJSON.status === USED) {
      setIsLoading(false);
      setShowActionButton(true);
      setResponseLanguageKey('used');
    } else {
      setIsError(true);
      setIsLoading(false);
      setShowActionButton(true);
      setResponseLanguageKey('error');
    }
  }, [redirectToSuspiciousUser]);
  const onLoad = useCallback(() => {
    const credentialParams = {
      otp,
      otpId,
      loginRedirectUrl: next
    };
    appCookieLogin.login(credentialParams).then(responses => {
      const response = responses[0];
      redirectByStatus(response);
    }).catch(handleError);
  }, [otp, otpId, next, redirectByStatus, handleError]);
  const resendHandler = useCallback(() => {
    const initOTPParams = {
      otpEmailTemplate: template,
      userId,
      redirectUrl: next
    };
    loginDao.otpLoginInit(initOTPParams).then(() => {
      setShowActionButton(false);
      setResponseLanguageKey('resent');
    }).catch(handleError);
  }, [template, userId, next, handleError]);
  return useMemo(() => ({
    isLoading,
    responseLanguageKey,
    onLoad,
    resendHandler,
    showActionButton,
    isError,
    templateLanguageKey: returnTemplateLanguageKey()
  }), [isLoading, responseLanguageKey, onLoad, resendHandler, showActionButton, isError, returnTemplateLanguageKey]);
};