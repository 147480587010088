import styled from 'styled-components';
export const AppleSignInDiv = styled.button.withConfig({
  displayName: "AppleLoginButtonstyles__AppleSignInDiv",
  componentId: "fw8yvo-0"
})(["align-items:center;background-color:", ";border-radius:0.1875rem;border:1px solid ", ";color:", ";cursor:", ";display:flex;font-family:'Segoe',sans-serif;font-weight:600;width:100%;gap:12px;height:41px;justify-content:center;margin:0;padding:0 12px;position:relative;text-align:center;&:hover{background-color:#eaf0f6;color:", ";text-decoration:none;}"], props => props.disabled ? '#eaf0f6' : 'white', props => props.disabled ? '#eaf0f6' : 'black', props => props.disabled ? '#b0c1d4' : 'black', props => props.disabled ? 'not-allowed' : 'pointer', props => props.disabled ? '#b0c1d4' : 'black');
export const ButtonText = styled.span.withConfig({
  displayName: "AppleLoginButtonstyles__ButtonText",
  componentId: "fw8yvo-1"
})(["flex-grow:1;padding-right:", ";"], props => props.smartLogin ? '21px' : '12px');
export const ImageWrapper = styled.span.withConfig({
  displayName: "AppleLoginButtonstyles__ImageWrapper",
  componentId: "fw8yvo-2"
})(["min-width:max-content;> *{filter:", ";}"], props => props.disabled ? 'opacity(0.5)' : 'none');