import styled from 'styled-components';
export const MicrosoftSignInDiv = styled.button.withConfig({
  displayName: "MicrosoftLoginButtonstyles__MicrosoftSignInDiv",
  componentId: "sc-1gfy56t-0"
})(["align-items:center;background-color:", ";border-radius:0.1875rem;color:", ";cursor:", ";border:1px solid ", ";display:flex;font-family:'Segoe',sans-serif;font-weight:600;width:100%;gap:12px;height:41px;justify-content:center;margin:0;padding:0 12px;position:relative;text-align:center;&:hover{background-color:", ";color:", ";text-decoration:none;}"], props => props.disabled ? '#eaf0f6' : '#2f2f2f', props => props.disabled ? '#b0c1d4' : 'white', props => props.disabled ? 'not-allowed' : 'pointer', props => props.disabled ? '#eaf0f6' : 'black', props => props.disabled ? '#eaf0f6' : '#4d4d4d', props => props.disabled ? '#b0c1d4' : 'white');
export const ButtonText = styled.span.withConfig({
  displayName: "MicrosoftLoginButtonstyles__ButtonText",
  componentId: "sc-1gfy56t-1"
})(["flex-grow:1;padding-right:", ";"], props => props.smartLogin ? '21px' : '12px');
export const ImageWrapper = styled.span.withConfig({
  displayName: "MicrosoftLoginButtonstyles__ImageWrapper",
  componentId: "sc-1gfy56t-2"
})(["min-width:max-content;> *{filter:", ";}"], props => props.disabled ? 'grayscale(100%)' : 'none');