import { useCallback, useMemo } from 'react';
import PasskeyLoginDao from '../data/PasskeyLoginDao';
import I18n from 'I18n';
import { useQueryData } from './useQueryData';
import { arrayBufferToBase64 } from '../utils/arrayBufferToBase64';
// @ts-expect-error no types from package
import HubSpotOnlyAppCookieLogin from 'Nabisco/app/HubSpotOnlyAppCookieLogin';
import { arePasskeysSupported } from '../utils/arePasskeysSupported';
const passkeyLoginDao = new PasskeyLoginDao();
export const usePasskeyLogin = ({
  handleError,
  requiredPortalId,
  rememberLogin
}) => {
  const {
    queryData
  } = useQueryData();
  const {
    loginPortalId,
    loginRedirectUrl
  } = queryData;
  const portalId = requiredPortalId || loginPortalId;
  const getPublicKey = async publicKeyCredentialRequestOptions => {
    // Transform the public key to the expected data types expected by navigator.credentials.create
    const publicKey = Object.assign({}, publicKeyCredentialRequestOptions, {
      challenge: Uint8Array.from(publicKeyCredentialRequestOptions.challenge, letter => letter.charCodeAt(0))
    });
    const passkeysSupported = await arePasskeysSupported();
    if (passkeysSupported) {
      return await navigator.credentials.get({
        publicKey
      });
    } else {
      throw new Error(I18n.text('login.unsupported.passkeys'));
    }
  };
  const loginWithPasskey = useCallback(async email => {
    try {
      const initiateResponse = await passkeyLoginDao.initiate(email);
      const publicKeyCredential = await getPublicKey(initiateResponse);
      const authenticatorResponse = publicKeyCredential.response;
      const transformedPublicKey = {
        rawId: arrayBufferToBase64(publicKeyCredential.rawId),
        response: {
          authenticatorData: arrayBufferToBase64(authenticatorResponse.authenticatorData),
          clientDataJSON: arrayBufferToBase64(authenticatorResponse.clientDataJSON),
          signature: arrayBufferToBase64(authenticatorResponse.signature),
          userHandle: authenticatorResponse.userHandle ? arrayBufferToBase64(authenticatorResponse.userHandle) : authenticatorResponse.userHandle
        },
        id: publicKeyCredential.id,
        type: publicKeyCredential.type
      };
      const appCookieLogin = new HubSpotOnlyAppCookieLogin({
        loginRequest: passkeyLoginDao.validate.bind(passkeyLoginDao),
        passThroughFallback: true
      });
      const validateResponse = await appCookieLogin.login({
        publicKey: transformedPublicKey,
        publicKeyCredentialRequestOptions: initiateResponse,
        userEmail: email,
        loginPortalId: portalId,
        loginRedirectUrl,
        rememberLogin
      });
      appCookieLogin.redirectOnLoginSuccess(validateResponse);
    } catch (error) {
      if (handleError) {
        handleError(error);
      }
    }
  }, [handleError, portalId, loginRedirectUrl, rememberLogin]);
  return useMemo(() => ({
    loginWithPasskey
  }), [loginWithPasskey]);
};