/* hs-eslint ignored failing-rules */

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'Nabi... Remove this comment to see the full error message
import BaseCookieLogin from 'Nabisco/BaseCookieLogin';
import { stringify } from 'hub-http/helpers/params';
import Raven from 'raven-js';
import { FAILED_LOGIN_PAGES } from '../constants/FailedLoginPages';
import { validateVettingUrl } from '../utils/urls';
const IGNORE_REDIRECT_WRAPPER = new BaseCookieLogin().ignoreRedirectWrapper;
export default {
  create(triggerReset, transitionTo, extraSearchObject = {}) {
    return ({
      responseJSON
    }) => {
      if (responseJSON) {
        const {
          status,
          errorTokens
        } = responseJSON;
        if (status in FAILED_LOGIN_PAGES) {
          var _responseJSON$errorTo;
          let searchString = `?${stringify(extraSearchObject)}`;
          if (responseJSON.errorTokens && responseJSON.errorTokens.email) {
            searchString = `?${stringify(Object.assign({
              email: responseJSON.errorTokens.email[0]
            }, extraSearchObject))}`;
          }
          const vettingUrl = (responseJSON === null || responseJSON === void 0 || (_responseJSON$errorTo = responseJSON.errorTokens) === null || _responseJSON$errorTo === void 0 || (_responseJSON$errorTo = _responseJSON$errorTo.vettingUrl) === null || _responseJSON$errorTo === void 0 ? void 0 : _responseJSON$errorTo[0]) || '';
          if (responseJSON.errorTokens && vettingUrl) {
            searchString = `?${stringify(Object.assign({
              vettingUrl
            }, extraSearchObject))}`;
          }
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const nextPath = `${FAILED_LOGIN_PAGES[status]}${searchString}`;
          // validate vetting url before redirecting to vetting page
          if (status === 'DEACTIVATED_USER' && !validateVettingUrl(vettingUrl)) {
            triggerReset(status, errorTokens);
          } else {
            transitionTo(nextPath);
          }
        } else {
          triggerReset(status, errorTokens);
        }
      } else {
        triggerReset('LOGIN_UNAVAILABLE');
      }
    };
  },
  createRedirectIgnored(triggerReset, transitionTo, extraSearchObject) {
    return IGNORE_REDIRECT_WRAPPER(this.create(triggerReset, transitionTo, extraSearchObject));
  },
  createRavenReporter(message, triggerReset, transitionTo, extraSearchObject) {
    const handler = this.createRedirectIgnored(triggerReset, transitionTo, extraSearchObject);
    return response => {
      let errorStatus = response.status.toString();
      if (response.responseJSON) {
        /* eslint-disable-next-line hubspot-dev/no-confusing-browser-globals */
        errorStatus = response.responseJSON.status || status;
      }
      Raven.captureMessage(message, {
        extra: {
          response: response.responseText,
          status: response.status
        },
        tags: {
          'error.status': errorStatus
        }
      });
      return handler(response);
    };
  }
};