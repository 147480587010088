'use es6';

import hubspotMobileApp from 'bender-url!../../img/hubspotMobileApp.png';
import microsoftLogo from 'bender-url!../../img/ms-logo.png';
import googleLogo from 'bender-url!../../img/google-logo.png';
import chatSpotLogo from '!bender-url!../../img/chatspot-icon.png';
import connectLogo from '!bender-url!../../img/connect.com-full-logo-color.svg';
import appleLogo from 'bender-url!../../img/apple-logo.png';
import loginMarketingImage from 'bender-url!../../img/SUI_desktop.svg';
import desktopCircle from 'bender-url!../../img/circle_desktop.svg';
import desktopDonut from 'bender-url!../../img/donut_desktop.svg';
import mobileDonut from 'bender-url!../../img/donut_mobile.svg';
import hubspotLogo from 'bender-url!../../img/HubSpot.svg';
import passkeyLogo from 'bender-url!../../img/passkey-logo.svg';
import engageAILogo from 'bender-url!../../img/engage-ai-logo.svg';
import unsupportedBrowser from 'bender-url!../../img/unsupported-browser.svg';
export default {
  googleLogo,
  hubspotMobileApp,
  microsoftLogo,
  chatSpotLogo,
  connectLogo,
  appleLogo,
  loginMarketingImage,
  desktopCircle,
  desktopDonut,
  mobileDonut,
  hubspotLogo,
  passkeyLogo,
  engageAILogo,
  unsupportedBrowser
};