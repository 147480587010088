import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import MicrosoftLoginDao from '../data/MicrosoftLoginDao';
import LoginFailureHandlerFactory from '../lib/LoginFailureHandlerFactory';
import { useQueryData } from './useQueryData';
import { useRedirect } from './useRedirect';
import { returnLoginRedirectOrChatspotRedirect } from '../utils/urls';
const microsoftLoginDao = new MicrosoftLoginDao();
export const useMicrosoftLogin = ({
  handleError,
  requiredPortalId,
  rememberLogin
} = {}) => {
  const history = useHistory();
  const {
    queryData
  } = useQueryData();
  const {
    loginPortalId,
    loginRedirectUrl
  } = queryData;
  const {
    redirectToUrl
  } = useRedirect(queryData);
  // TODO: remove it after testing
  // const portalId = requiredPortalId || loginPortalId;
  let portalId = undefined;
  if (requiredPortalId) {
    portalId = requiredPortalId;
  } else if (loginPortalId) {
    portalId = loginPortalId;
  }
  const loginWithMicrosoft = useCallback(() => {
    microsoftLoginDao.initialize({
      loginPortalId: portalId,
      loginRedirectUrl: returnLoginRedirectOrChatspotRedirect(loginRedirectUrl, portalId),
      rememberLogin
    }).then(({
      redirectUrl
    }) => {
      redirectToUrl(redirectUrl);
    }).catch(LoginFailureHandlerFactory.createRavenReporter('Microsoft initialization failed', err => {
      if (handleError) {
        handleError(err);
      }
    }, history.push));
  }, [handleError, loginRedirectUrl, history, redirectToUrl, portalId, rememberLogin]);
  return useMemo(() => ({
    loginWithMicrosoft
  }), [loginWithMicrosoft]);
};