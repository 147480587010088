import { stylePropKeys } from '../styleProps';
import { ariaPropNames, domPropNames, linkPropNames } from '../domProps';
export const callbackPropNames = new Set(['onClick']);
const imagePropNames = new Set(['alt', 'src', 'srcSet', 'sizes', 'onLoad', 'onError']);
const textAreaPropNames = new Set(['value', 'defaultValue', 'placeholder', 'name', 'id', 'rows', 'cols', 'wrap', 'maxLength', 'disabled', 'readOnly', 'required', 'autoFocus', 'spellCheck']);
const defaultOpts = {
  isLink: false,
  isImage: false,
  isTextArea: false,
  disallowedProps: ['className', 'style']
};
const dataAttributePrefix = 'data-';

/**
 * Filters props passed to a component into the following categories:
 *
 * - DOM props: data and aria attributes, role, id, etc.
 * - Token props: CSS-conformant token properties allowed by the design system.
 * - Callback props: Event handlers and other functions.
 */
export function filterProps(props, opts) {
  const options = Object.assign({}, defaultOpts, opts);
  const {
    isLink,
    isImage,
    isTextArea,
    disallowedProps
  } = options;
  const disallowedPropNames = new Set(disallowedProps);
  const domProps = {};
  const tokenProps = {};
  const callbackProps = {};
  const restProps = {};
  Object.keys(props).forEach(key => {
    if (domPropNames[key] || ariaPropNames[key] || isLink && linkPropNames[key] || isImage && imagePropNames.has(key) || isTextArea && textAreaPropNames.has(key) || key.startsWith(dataAttributePrefix)) {
      domProps[key] = props[key];
    } else if (stylePropKeys[key]) {
      tokenProps[key] = props[key];
    } else if (callbackPropNames.has(key) || typeof props[key] === 'function') {
      callbackProps[key] = props[key];
    } else if (!disallowedPropNames.has(key)) {
      restProps[key] = props[key];
    }
  });
  return {
    domProps,
    tokenProps,
    callbackProps,
    restProps
  };
}