const SCRIPT_ID = 'UILinkSpeculationRules';
export const prerenderClasses = {
  conservative: 'prerender-c',
  eager: 'prerender-e',
  moderate: 'prerender-m'
};
function createSpeculationRules() {
  return {
    prerender: [{
      source: 'document',
      where: {
        and: [{
          selector_matches: '.prerender-c'
        }]
      },
      eagerness: 'conservative'
    }, {
      source: 'document',
      where: {
        and: [{
          selector_matches: '.prerender-e'
        }]
      },
      eagerness: 'eager'
    }, {
      source: 'document',
      where: {
        and: [{
          selector_matches: '.prerender-m'
        }]
      },
      eagerness: 'moderate'
    }]
  };
}
function isSpeculationSupported() {
  return !!(HTMLScriptElement.supports && HTMLScriptElement.supports('speculationrules'));
}
function isScriptSet() {
  return !!document.getElementById(SCRIPT_ID);
}
export default function setSpeculationRules() {
  if (!isSpeculationSupported() || isScriptSet()) {
    return;
  }
  const newScript = document.createElement('script');
  newScript.id = SCRIPT_ID;
  newScript.type = 'speculationrules';
  newScript.textContent = JSON.stringify(createSpeculationRules());
  document.body.append(newScript);
}