import { parse as parseQuery } from 'hub-http/helpers/params';
import env from 'enviro';
import { SignupEmbedderOptions } from 'signup-embedder';
import { getSubDomain } from './urls';
const SAFE_REFERRERS = ['https://mail.google.com/'];
const getSafeReferrerForEmbedder = () => {
  const qaVar = env.isQa() ? 'qa' : '';
  const referrer = `https://app.hubspot${qaVar}.com/signup-hubspot`;
  return referrer.toLowerCase();
};
const getSafeReferrerForOAuthBridge = () => {
  const qaVar = env.isQa() ? 'qa' : '';
  const referrer = `https://app.hubspot${qaVar}.com/oauth-bridge`;
  return referrer.toLowerCase();
};
const parseUrl = url => {
  const search = url ? url.split('?')[1] : undefined;
  const query = search ? parseQuery(search) : {};
  return query;
};
const keepOpenerForEmbedder = url => {
  const activeEmbedder = parseUrl(url)[SignupEmbedderOptions.SIGNUP_EMBEDDER_IS_ACTIVE];
  const curReferrer = document.referrer ? document.referrer : '';
  const isSafeReferrer = curReferrer.toLowerCase().includes(getSafeReferrerForEmbedder()) && activeEmbedder || curReferrer.toLowerCase().includes(getSafeReferrerForOAuthBridge());
  return isSafeReferrer;
};
const doNotCheckOpenerTwice = () => {
  const curUrl = window.location.href;
  const betaVar = curUrl.split('/').slice(-1)[0] === 'beta' ? '' : '/beta';
  const qaVar = env.isQa() ? 'qa' : '';
  const subdomain = getSubDomain();
  const safeLoginReferrer = `https://${subdomain}.hubspot${qaVar}.com/login${betaVar}`;
  const docRef = document.referrer.split('?')[0];
  return safeLoginReferrer === docRef;
};
export const checkSafeOpener = ({
  loginRedirectUrl
}) => {
  if (window.opener) {
    try {
      if (window.opener.origin) {
        console.log('safe origin');
      } else {
        throw new Error('Unsafe origin');
      }
    } catch (error) {
      if (SAFE_REFERRERS.includes(document.referrer)) {
        console.log('safe referrer');
      } else if (keepOpenerForEmbedder(loginRedirectUrl)) {
        console.log('safe embedder');
      } else if (doNotCheckOpenerTwice()) {
        console.log('already checked');
      } else {
        console.log('unsafe');
        window.opener = null;
      }
    }
  } else {
    console.log('safe no opener');
  }
};